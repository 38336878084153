import { eventSender } from 'Lib/event-sender/event-sender';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');
const sForm = '.mail-form__form';

onScreen($('.mail-form_uni, .mail-form__uni-pic, .mail-form_pic'), loadSrcOrBg);

$body.on('eksmoThisAjaxFormSuccess', sForm, function () {
    const $thisForm = $(this);
    const vendor = $thisForm.find('input[name=vendor]').first().val();

    if ($thisForm.closest('.mail-form_book').length || $thisForm.closest('.mail-form_book2020').length) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'engagement',
            eventCategory: 'Узнать о поступлении книги',
            eventLabel: $('.book-page__card-title').first().text().trim().replace('Электронная книга ', '').replace('Аудиокнига ', '').replace(/['"«»]/g, ''),
        });
    } else if (isNoEmptyString(vendor)) {
        eventSender.eventSubscribe(vendor);
    }
});